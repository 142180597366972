import React, { useEffect, useState } from 'react'
import './App.css'
import Viewer360 from './components/display/viewer360/viewer360'
import image1 from './assets/render360-1.png'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

const client = new W3CWebSocket(process.env.REACT_APP_WEBSOCKET_URL ?? '')

function sendData (method: string, path: string, body: any = {}) {
  client.send(JSON.stringify({ method, path, body }))
}
const request = {
  get: (path: string, body: any = {}) => sendData('get', path, body),
  post: (path: string, body: any = {}) => sendData('post', path, body),
  update: (path: string, body: any = {}) => sendData('update', path, body),
  delete: (path: string, body: any = {}) => sendData('delete', path, body)
}

function App () {
  const [messages, setMessages] = useState<string[]>([])
  const [currentMessage, setCurrentMessage] = useState('')

  useEffect(() => {
    client.onopen = () => {
      console.log('WebSocket Client Connected')
      window.setTimeout(() => {
        console.log('Sending join and request messages')
        request.post('/players/join', { name: 'Lex' })
        request.get('/players/messages/')
      }, 100)
    }
    client.onmessage = (message) => {
      const { status, path, body } = JSON.parse(message.data as string)
      console.log(`Message for ${path} received`)
      if (status !== 200) {
        console.log(`An error ${status} occurred: `, body.data)
        return
      }
      const { data } = body
      switch (path) {
        case '/players/events/joined': {
          setMessages((oldMessages) => ([...oldMessages, `* New Player ${data.name} joined`] as never[]))
          break
        }
        case '/players/messages': {
          const messagesFromServer: string[] = data.messages.map(([name, message]: [string, string]) => name + ': ' + message)
          setMessages((oldMessages) => ([...oldMessages, ...messagesFromServer]))
          break
        }
        case '/players/events/new-message': {
          setMessages((oldMessages) => ([...oldMessages, `${data.name}: ${data.message}`] as never[]))
          break
        }
      }
    }
    client.onclose = (event) => {
      console.log('WebSocket Client Disconnected')
    }
  }, [])

  return (
    <div className="App">
      <Viewer360 image={image1} />
      <div id="message-box">
        {messages.map((message, index) => <p key={index}>{message}</p>)}
        <input value={currentMessage} onChange={(event) => setCurrentMessage(event.target.value)} />
        <button onClick={() => {
          request.post('/players/new-message/', { name: 'Lex', message: currentMessage })
          setCurrentMessage('')
        }}>Submit</button>
      </div>
    </div>
  )
}

export default App
