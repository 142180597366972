import React from 'react'
// @ts-ignore
import ReactPannellum from 'react-pannellum'
// @ts-ignore
// import { Pannellum } from 'react-pannellum'

type Viewer360Props = { image: string };
const Viewer360 = ({ image }: Viewer360Props) => {
  // return <img src={image} />
  return <ReactPannellum
      id="1"
      sceneId="firstScene"
      imageSource={image}
      config={{ autoLoad: true }}
      style={{ width: '100%', height: '100%' }}
  />
  // return <Pannellum
  //     width="100%"
  //     height="100%"
  //     image={image}
  //     pitch={10}
  //     yaw={180}
  //     hfov={110}
  //     autoLoad
  //     showZoomCtrl={false}
  // >
  // </Pannellum>
}

export default Viewer360
